import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function ShopItem({ product, type }) {
  const { thumbnailUrl, title, url } = product;

  return (
    <div className="cols watch_item-list">
      <Link to={url}>
        <div className={`watch_item-figure media ${type === 'landscape' ? 'ratio_16-9' : 'ratio_9-16'}`}>
          <div className="m_overlay" />
          <div
            className="m_overlay-image bgImage"
            style={{ backgroundImage: `url(${thumbnailUrl})`, backgroundSize: 'contain' }}
          />
          <div className="media-content">
            <p className="related-content-item-title">{title}</p>
            <div className="btn btn-watch-play btn-transparent">
              <div className="btn btn-sm btn-primary-ii btn-watch-now mg-t-0">Purchase Now</div>
            </div>
          </div>

          <div className="m_border" />
        </div>
      </Link>
    </div>
  );
}
