import React from 'react';
import WatchItem from '../Watch/WatchItem';
import { ReadItem } from '../../modules/read-page/read-category';
import ShopItem from './ShopItem';
import PodCastCard from './podcast-card';
import { Link } from 'gatsby';
import iconPlayAlt from '../../images/podcast-play.png';
const RelatedContent = ({ relatedContent }) => {
  return (
    <div>
      {' '}
      <div className="cat_watch-list">
        {relatedContent.length && (
          <>
            <div className="cat_watch-head cat-title pd-x-sm" style={{ marginBottom: '38px' }}>
              Related Content
            </div>
            <div className="d-flex columns-4">
              {relatedContent.map((ep, index) => {
                return ep.category === 'WATCH' ? (
                  <WatchItem
                    className="cols"
                    key={index}
                    data={ep}
                    type="landscape"
                    showPlayIcon
                    showTitleInBox={true}
                  />
                ) : ep.category === 'READ' ? (
                  <ReadItem
                    key={index}
                    read={ep}
                    item="col2"
                    showReadIcon={true}
                    type="landscape"
                    showTitleInBox={true}
                  />
                ) : ep.category === 'LISTEN' ? (
                  <div className="cols watch_item-list">
                    <Link to={`/podcast/${ep.uid}`}>
                      <div className={`watch_item-figure media ${'ratio_16-9'}`}>
                        <div className="m_overlay" />
                        <div
                          className="m_overlay-image bgImage"
                          style={{ backgroundImage: `url(${ep.data.thumnailImage.url})` }}
                        />

                        <div className="media-content">
                          <p className="related-content-item-title">{ep.data.podCastTitle[0].text}</p>

                          <div
                            className="btn btn-watch-play btn-transparent"
                            style={{
                              width: '48px',
                              height: '48px',
                              borderRadius: '50%',
                              backgroundColor: 'rgba(255, 255, 255, 0.17)',
                            }}
                          >
                            <img
                              src={iconPlayAlt}
                              alt="Icon Play"
                              className="icon-play-alt"
                              style={{ height: 17, width: 21 }}
                            />
                          </div>
                          <div className="btn-watch-play" style={{ display: 'inline', marginLeft: '10px' }}>
                            <span>LISTEN</span>
                          </div>
                        </div>

                        <div className="m_border" />
                      </div>
                    </Link>
                  </div>
                ) : (
                  <ShopItem product={ep} type="landscape" />
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RelatedContent;
